<template>
  <section>
    <!-- Header de vista -->
    <v-row  class="">
      <v-col cols="8">
        <p class="text-h6 secondary--text">Creación de proceso de compra</p>
        <p>Ejercicio {{ anioFiscal }}</p>
      </v-col>
      <v-col cols="4">
        <v-btn class="float-right text-capitalize" color="secondary" @click="saveListado">
          Siguiente
        </v-btn>
      </v-col>
    </v-row>

    <!-- Contenido de vista -->
    <!-- Stack de solicitudes -->
    <v-row class="my-0">
      <v-col cols="6">
        <p class="text-h6">Solicitudes</p>
        <!-- Filtros de búsqueda de solicitudes -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="ctlUnidad"
              item-text="nombre"
              item-value="id"
              label="Unidad"
              outlined
              dense
              clearable
              hide-details
              v-model="fltUnidadSolicitudModel"
              @change="obtainSolicitudes()"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-autocomplete
              :items="ctlTipoObs"
              item-text="nombre"
              item-value="id"
              label="Tipo de OBS"
              outlined
              dense
              clearable
              hide-details
              v-model="fltTipoObsSolicitudModel"
              @change="obtainSolicitudes()"
            />
          </v-col>
          <v-col cols="8  ">
            <v-autocomplete
              :items="ctlObs"
              item-text="nombre"
              item-value="id"
              label="OBS"
              outlined
              dense
              clearable
              hide-details
              v-model="fltObsSolicitudModel"
              @change="obtainSolicitudes()"
            />
          </v-col>
        </v-row>
        <!-- Expansion panel de solicitudes -->

        <v-expansion-panels
          class="mt-5"
          v-model="solicitudesExpansionPanelModel"
          multiple
        >
          <v-row class="mb-0" v-if="solicitudesError">
            <v-col cols="12">
              <v-alert color="red" icon="mdi mdi-alert" type="success"
                >Verifique los filtros</v-alert
              >
            </v-col>
          </v-row>
          <draggable
            class="parent-list"
            :class="solicitudesError ? 'draggable-error' : ''"
            :list="solicitudes"
            :group="solicitudesError ? 'error' : 'parent'"
            @add="onParentAdd"
            @start="onParentSolicitudesStart"
            @end="onEnd"
          >
            <v-expansion-panel
              active-class="expasion-panel-active"
              v-for="(solicitud, index) in solicitudes"
              :key="index"
            >
              <v-expansion-panel-header color="bgMinsal">
                {{ solicitud.codigo_obs + " - " + solicitud.nombre_obs }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="expansion-panel-content">
                <!-- <v-list> -->
                <draggable
                  class="child-list"
                  :data-parent-id="solicitud.id_obs"
                  :list="solicitud.items"
                  :group="'child-' + solicitud.id_obs"
                  @start="onSolicitudStart"
                  @end="onEnd"
                >
                  <proceso-compra-element-component
                    v-for="item in solicitud.items"
                    :key="item.id_solicitud_compra_detalle_obs"
                    :data="item"
                  />
                </draggable>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
      <!-- Stack de procesos -->
      <v-col cols="6">
        <p class="text-h6">Proceso</p>
        <!-- Filtros de búsqueda de procesos -->
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              :items="ctlUnidad"
              item-text="nombre"
              item-value="id"
              label="Unidad"
              outlined
              dense
              clearable
              hide-details
              v-model="fltUnidadProcesoModel"
              @change="filterProcesos()"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <v-autocomplete
              :items="ctlTipoObs"
              item-text="nombre"
              item-value="id"
              label="Tipo de OBS"
              outlined
              dense
              clearable
              hide-details
              v-model="fltTipoObsProcesoModel"
              @change="filterProcesos()"
            />
          </v-col>
          <v-col cols="8  ">
            <v-autocomplete
              :items="ctlObs"
              item-text="nombre"
              item-value="id"
              label="OBS"
              outlined
              dense
              clearable
              hide-details
              v-model="fltObsProcesoModel"
              @change="filterProcesos()"
            />
          </v-col>
        </v-row>
        <!-- Expansion panel de procesos -->
        <v-expansion-panels
          class="mt-5"
          v-model="procesosExpansionPanelModel"
          multiple
        >
          <v-row class="mb-0" v-if="procesosError">
            <v-col cols="12">
              <v-alert color="red" icon="mdi mdi-alert" type="success"
                >Verifique los filtros</v-alert
              >
            </v-col>
          </v-row>
          <draggable
            class="parent-list"
            :class="procesosError ? 'draggable-error' : ''"
            :list="procesos"
            :group="procesosError ? 'error' : 'parent'"
            @add="onParentAdd"
            @start="onParentProcesosStart"
            @end="onEnd"
          >
            <v-expansion-panel
              active-class="expasion-panel-active"
              v-for="(proceso, index) in procesos"
              :key="index"
            >
              <v-expansion-panel-header color="bgMinsal">
                {{ proceso.codigo_obs + " - " + proceso.nombre_obs }}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="expansion-panel-content">
                <v-list>
                  <draggable
                    class="child-list"
                    :data-parent-id="proceso.id_obs"
                    :list="proceso.items"
                    :group="'child-' + proceso.id_obs"
                    @start="onProcesoStart"
                    @end="onEnd"
                  >
                    <proceso-compra-element-component
                      v-for="item in proceso.items"
                      :key="item.id_solicitud_compra_detalle_obs"
                      :data="item"
                    />
                  </draggable>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </draggable>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import draggable from "vuedraggable";
import { mapActions, mapMutations, mapState } from "vuex";
import ProcesoCompraElementComponent from "./components/ProcesoCompraElementComponent.vue";

export default {
  name: "CrearProcesoCompraComponent",
  components: {
    draggable,
    ProcesoCompraElementComponent,
  },
  computed: {
    ...mapState("paacCrearProcesoCompraStore", [
      "solicitudesFiltradas",
      "procesosFiltrados",
      "fltUnidadSolicitud",
      "fltTipoObsSolicitud",
      "fltObsSolicitud",
      "fltUnidadProceso",
      "fltTipoObsProceso",
      "fltObsProceso",
      "ctlUnidad",
      "ctlTipoObs",
      "ctlObs",
      "solicitudesExpansionPanel",
      "procesosExpansionPanel",
      "procesosError",
      "solicitudesError",
    ]),
    ...mapState(["anioFiscal"]),
    // Computados para los items de las solicitudes y procesos
    solicitudes: {
      get() {
        return this.solicitudesFiltradas;
      },
      set(value) {
        this.setSolicitudesFiltradas(value);
      },
    },
    procesos: {
      get() {
        return this.procesosFiltrados;
      },
      set(value) {
        this.setProcesosFiltrados(value);
      },
    },
    // Modelos para los filtros desde vuex
    // -> solicitud
    fltUnidadSolicitudModel: {
      get() {
        return this.fltUnidadSolicitud;
      },
      set(value) {
        this.setFltUnidadSolicitud(value);
      },
    },
    fltTipoObsSolicitudModel: {
      get() {
        return this.fltTipoObsSolicitud;
      },
      set(value) {
        this.setFltTipoObsSolicitud(value);
      },
    },
    fltObsSolicitudModel: {
      get() {
        return this.fltObsSolicitud;
      },
      set(value) {
        this.setFltObsSolicitud(value);
      },
    },
    // -> proceso
    fltUnidadProcesoModel: {
      get() {
        return this.fltUnidadProceso;
      },
      set(value) {
        this.setFltUnidadProceso(value);
      },
    },
    fltTipoObsProcesoModel: {
      get() {
        return this.fltTipoObsProceso;
      },
      set(value) {
        this.setFltTipoObsProceso(value);
      },
    },
    fltObsProcesoModel: {
      get() {
        return this.fltObsProceso;
      },
      set(value) {
        this.setFltObsProceso(value);
      },
    },
    // Modelos para los expansion panels desde vuex
    solicitudesExpansionPanelModel: {
      get() {
        return this.solicitudesExpansionPanel;
      },
      set(value) {
        this.setSolicitudesExpansionPanel(value);
      },
    },
    procesosExpansionPanelModel: {
      get() {
        return this.procesosExpansionPanel;
      },
      set(value) {
        this.setProcesosExpansionPanel(value);
      },
    },
  },

  methods: {
    ...mapActions("paacCrearProcesoCompraStore", [
      "obtainSolicitudes",
      "fetchUnidades",
      "fetchTipoObs",
      "fetchObs",
      "onParentAdd",
      "onSolicitudStart",
      "onProcesoStart",
      "onEnd",
      "onProcesosChange",
      "replicarMovimientos",
      "filterProcesos",
      "onParentSolicitudesStart",
      "onParentProcesosStart",
    ]),
    ...mapMutations("paacCrearProcesoCompraStore", [
      "setFltUnidadSolicitud",
      "setFltTipoObsSolicitud",
      "setFltObsSolicitud",
      "setFltUnidadProceso",
      "setFltTipoObsProceso",
      "setFltObsProceso",
      "setSolicitudesExpansionPanel",
      "setProcesosExpansionPanel",
      "setSolicitudesFiltradas",
      "setProcesosFiltrados",
      "limpiarProcesos"
    ]),
    saveListado() {
      if (this.procesos.length > 0) {
        this.$router.push({ name: "paac-proceso-creacion" });
      } else {
        this.temporalAlert({
          show: true,
          type: "error",
          message: " El proceso debe contener al menos un elemento ",
        });
      }
    },
  },

  watch: {
    procesos: function (val) {
      // verificamos si algún id se repite
      let ids = [];
      let repetidos = [];
      val.forEach((proceso) => {
        if (ids.includes(proceso.id_obs)) {
          repetidos.push(proceso.id_obs);
        } else {
          ids.push(proceso.id_obs);
        }
      });
      // Si se repite algún id, se elimina el proceso y se agregan los items al proceso que ya existe
      if (repetidos.length > 0) {
        repetidos.forEach((id_obs) => {
          let index = val.findIndex((proceso) => proceso.id_obs == id_obs);
          let items = val[index].items;
          val.splice(index, 1);
          val.forEach((proceso) => {
            if (proceso.id_obs == id_obs) {
              proceso.items = proceso.items.concat(items);
            }
          });
        });
      }

      this.replicarMovimientos();
    },
    solicitudes: function (val) {
      // verificamos si algún id se repite
      let ids = [];
      let repetidos = [];
      val.forEach((solicitud) => {
        if (ids.includes(solicitud.id_obs)) {
          repetidos.push(solicitud.id_obs);
        } else {
          ids.push(solicitud.id_obs);
        }
      });
      // Si se repite algún id, se elimina el proceso y se agregan los items al proceso que ya existe
      if (repetidos.length > 0) {
        repetidos.forEach((id_obs) => {
          let index = val.findIndex((solicitud) => solicitud.id_obs == id_obs);
          let items = val[index].items;
          val.splice(index, 1);
          val.forEach((solicitud) => {
            if (solicitud.id_obs == id_obs) {
              solicitud.items = solicitud.items.concat(items);
            }
          });
        });
      }

      this.replicarMovimientos();
    },
  },

  created() {
    this.obtainSolicitudes();
    this.fetchUnidades();
    this.fetchTipoObs();
    this.fetchObs();
    // Limpiamos información de vuex que se haya podido guardar en la sesión anterior
    this.limpiarProcesos();
  },
};
</script>

<style lang="scss">
.draggable-error {
  border: 2px solid red;
  animation: shake 0.5s;
  animation-iteration-count: 2;
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}
.expansion-panel-content {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.expasion-panel-active {
  .v-expansion-panel-header--active {
    background-color: var(--v-tableAccent-base) !important;
    color: #fff;
    .v-icon {
      color: #fff !important;
    }
  }
}

.parent-list {
  width: 100%;
  min-height: 800px;
  max-height: 800px;
  overflow: scroll;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}

.child-list {
  min-height: 150px;
}
</style>