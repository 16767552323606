<template>
  <div
    class="proceso-compra-element"
    :data-id-unidad="data.id_unidad"
    :data-id-tipo-obs="data.id_obs_clasificacion"
    :data-id-obs="data.id_obs"
  >
    <v-list color="bgMinsal">
      <v-list-item class="ma-0">
        <v-list-item-content pa-0>
          <v-list-item-title>
            {{ data.unidad }}
          </v-list-item-title>
          <v-list-item-subtitle> </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item class="ma-0">
        <v-list-item-content class="pa-0">
          <v-list-item-subtitle>
            Especificación: {{ data.especificacion }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-row>
      <v-col cols="12">
        <!-- simple Tabla de 4 columnas -->
        <table class="table">
          <thead>
            <tr>
              <th class="text-left">Cantidad</th>
              <th class="text-left">Unidad de medida</th>
              <th class="text-left">Precio unitario</th>
              <th class="text-left">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-left">
                {{ data.cantidad }}
              </td>
              <td class="text-left">
                {{ data.unidad_medida }}
              </td>
              <td class="text-left">
                {{ formatoMiles(data.precio_unitario.toFixed(2))}}
              </td>
              <td class="text-left">
                {{  formatoMiles(data.total.toFixed(2)) }}
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ProcesoCompraElementComponent",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatoMiles (number) {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = '$1,';
        let arr = number.toString().split('.');
        arr[0] = arr[0].replace(exp,rep);
        return arr[1] ? arr.join('.'): arr[0];
      }
  },
};
</script>
<style lang="scss" scope>
.proceso-compra-element {
  width: 100%;
  margin: 8px 0px;
  padding: 0px 8px 16px 8px;
  border-radius: 4px;
  background-color: var(--v-bgMinsal-base);
}

.table {
  margin: 0px 16px;
  width: 80%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0px;
  font-size: 14px;
  th {
    // Sin color de fondo color del texto negro bold
    background-color: var(--v-bgMinsal-base) !important;
    color: var(--v-textMinsal-base) !important;
    font-weight: bold !important;
  }
  tr {
    // Texto centrado en la celda
    text-align: center !important;
  }
}
</style>
